.UserGroupDialogContent__container {
  height: 90vh;
  width: 36rem;
}

.UserGroupDialogContent__errorMessage {
  display: block;
  padding-top: 0.5rem;
  padding-left: 5.5rem;
  color: #ff1d2e !important; /* Override spectrum-FieldLabel color attribute here */
}

.UserGroupDialogNoContent {
  text-align: center;
  font-style: italic;
}

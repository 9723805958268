/*
  Reference for using Adobe-Clean fonts (including fallbacks) for CCJK locales
    - https://git.corp.adobe.com/world-readiness/globalization-shared/blob/master/guidelines/fonts/03_using_adobe_clean_fonts_for_web_app.md
*/

/* Base style for HLText without mouse interactions */
/* 
  Also specifies Adobe-Clean fonts and fallbacks for HTML text for most locales
  This makes the text font similar to React-Spectrum fonts
*/
.HLText {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  color: rgb(75, 75, 75);
  font-family: adobe-clean, 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif; /* Adobe-Clean fonts and fallbacks similar to React-Spectrum fonts */
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  cursor: text;
}

/* 
  Specify Adobe-Clean fonts and fallbacks for HTML text for Chinese Simpified locale
  This makes the text font similar to React-Spectrum fonts
  adobe-clean-han-simplified-c is not expected to render without typekit, instead SimSun is the expected fallback
*/
.HLText:lang(zh-Hans) {
  font-family: 'adobe-clean-han-simplified-c', 'SimSun', 'Heiti SC Light', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for HTML text for Chinese Traditional locale
  This makes the text font similar to React-Spectrum fonts
  adobe-clean-han-traditional is not expected to render without typekit, instead MingLiu is the expected fallback
*/
.HLText:lang(zh) {
  font-family: 'adobe-clean-han-traditional', 'MingLiu', 'Heiti TC Light', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for HTML text for Korean locale
  This makes the text font similar to React-Spectrum fonts
  adobe-clean-han-korean is not expected to render without typekit, instead Malgun Gothic is the expected fallback
*/
.HLText:lang(ko) {
  font-family: 'adobe-clean-han-korean', 'Malgun Gothic', 'Apple Gothic', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for HTML text for Japanese locale
  This makes the text font similar to React-Spectrum fonts
  adobe-clean-han-japanese is not expected to render without typekit, instead Yu Gothic is the expected fallback
*/
.HLText:lang(ja) {
  font-family: 'adobe-clean-han-japanese', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Osaka', YuGothic,
    'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
}

.HLText__hover:hover {
  cursor: pointer;
}

.HLText__selected {
  background-color: orange !important; /* important used to override any default coloring given by other css */
  color: rgb(75, 75, 75) !important; /* important used to override any default coloring given by other css */
}

.HLText__highlight {
  background-color: yellow !important; /* important used to override any default coloring given by other css */
  color: rgb(75, 75, 75) !important; /* important used to override any default coloring given by other css */
}

.HLText__hovered {
  color: blue !important; /* important used to override any default coloring given by other css */
  text-decoration: underline;
}

.EditCompartment__tabSection--rightAligned {
  margin-right: 1rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
}

.EditCompartment_tabSectionHeader {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.MoreButton {
  padding: 0.5rem;
  min-width: unset;
  height: unset;
  border: 0;
}

.MoreDropdown {
  display: inline-block;
  float: right;
}

.MoreOptionsTableCell {
  width: 1rem;
}

.EditCompartment table {
  width: 100%;
}

.Load_wait {
  left: 50%;
  margin-top: 3rem;
}

.EditCompartment_tabContent {
  padding-right: 1rem;
  padding-bottom: 1.5rem;
}

.EditCompartment__stickyHeader th {
  position: sticky;
  top: 0;
  background-color: #f5f5f5 !important;
  z-index: 1;
}

.EditCompartment_errorMessage {
  color: red;
}

.EditCompartment__listCountDisplay {
  margin-right: 0.5rem;
  line-height: 2rem;
}

.ProductTable__productIcon {
  height: 1.2rem;
  margin-right: 0.5rem;
}

.UserGroupTable__productIcon {
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  vertical-align: center;
}

.EditCompartment__margin--top {
  margin-top: 1rem;
}

.EditCompartment__margin--left {
  margin-left: 1rem;
}

.EditCompartment__margin--bottom {
  margin-bottom: 1rem;
}

.EditCompartment__font--light {
  font-size: smaller;
  font-weight: lighter;
  color: grey;
  font-style: italic;
}

/* HelpBubble formats for tooltip help that appear on most panes and pages.  React Spectrum defaul width is very narrow.
   Learn more link also ends up blue on blue background. span max-width needs to be a bit smaller than tooltip width
   to accommodate the little arrow that gets included.  Tooltip styles are dynamically generated by react so this code
   it tricky to debug and get to work.
 */
.HelpBubble__helpBubble {
  float: right;
  margin-top: 6px;
  margin-left: 8px;
}
.HelpBubble__helpBubble_left {
  float: right;
  margin-left: 8px;
  margin-top: -4px;
}

.HelpBubble__toolTip {
  width: 300px;
}

.HelpBubble__link {
  text-decoration: underline;
}

.HelpBubble__toolTip span {
  max-width: 290px !important;
  font-size: 14px;
  line-height: 18px;
}

.EditCompartment__TableSearch .spectrum-Textfield.spectrum-Search-input {
  /* override spectrum css for search box such that placeholder text is not truncated */
  width: 18rem;
}

.EditCompartment__EditProfileDialog__UserGroupSectionAndAdminSection {
  width: 100%; /* set width of user group section in edit profile to 100% of dialog width */
}

.EditCompartment__EditProfileDialog__UserGroupSectionAndAdminSection .spectrum-Textfield.spectrum-InputGroup-field {
  width: 90%; /* set width of combobox to 90% of its container (leaving some room for dropdown button) */
}

.EditCompartmentTabs__Name {
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.EditCompartmentTabs__NameTooltip {
  /* override spectrum tooltip css. Otherwise tooltip is too long vertically and too short horizontally. */
  display: unset;
  width: 15rem;
}

.EditCompartment__ScrollableComponent {
  overflow: auto;
}

.EditCompartment_pageButton {
  border-radius: 16px;
  height: 32px;
}

.DirectoryTable__statusColumn {
  display: inline-block;
}

.DirectoryTable__rightContainer {
  float: right;
  margin-left: auto;
}

.DirectoryTable__enDashAsDEState__margin {
  /* Following is similar to the one used by AAC */
  margin-left: var(--spectrum-global-dimension-size-400, var(--spectrum-alias-size-400));
}

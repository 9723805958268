.ImportDialog__container {
  height: 50vh;
  width: 33%;
}

.ImportDialog__errorMessage {
  color: red;
  text-align: left;
  margin-top: 2rem;
  white-space: pre-wrap;
}

.ImportDialog__btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ImportDialog_successMessage {
  color: #1473e6;
  text-align: left;
  white-space: pre-wrap;
}

.ImportDialog__ImportingLabel {
  font-weight: bold;
  color: #1473e6;
}

.ImportDialog__ProgressBar {
  margin-top: 0.5rem;
}

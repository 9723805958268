.OrgMigration__top {
  border-bottom: 0.1rem solid #e1e1e1;
  width: 100%;
}

.OrgMigrationSelect__list {
  text-align: left;
  width: 100%;
  max-height: 25rem;
}

.OrgMigrationDetails__button {
  float: right;
}

.OrgMigationDialog__span {
  display: block;
}

.OrgMigration__comboBox {
  min-width: 24rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.OrgMigration__comboBox > input {
  min-width: 16rem; /* Needed to make enough room for localized placeholders */
}

.OrgMigration__list {
  background-color: #ffffff;
  margin-left: 6rem;
  margin-right: 6rem;
}
.OrgMigration__button {
  margin-left: 2rem;
  margin-bottom: 4rem;
}

.OrgMigration__filter {
  margin-left: 2rem;
  margin-right: 6rem;
  background-color: white;
}
.OrgMigration__filter > input {
  min-width: 16rem; /* Needed to make enough room for localized placeholders */
}

.OrgMigration__Label {
  margin-left: 2rem;
  font-size: medium;
}

.OrgMigration__Paragraph {
  margin-left: 2rem;
  font-size: medium;
}

.OrgMigration__alert {
  margin-left: 2rem;
}
.OrgMigration__datatable {
  margin-left: 2rem;
  margin-right: 6rem;
  margin-bottom: 1rem;
}
.OrgMigration__datatable > .p-component {
  font-family: unset !important;
  font-size: unset !important;
  word-break: break-all;
}

/* Tree Table Related */

.OrgMigration__TreeTable {
  margin-top: 1rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
}

.OrgMigration__TreeTable tr {
  font-weight: unset !important;
  color: unset !important;
  background: inherit !important;
  border-radius: 1rem;
}

.OrgMigration__TreeTable .p-treetable .p-treetable-tbody > tr:focus + tr > td {
  box-shadow: unset !important;
  outline: unset !important;
}

.OrgMigration__TreeTable .p-treetable .p-treetable-tbody > tr:focus > td {
  box-shadow: unset !important;
  outline: unset !important;
}

.OrgMigration__TreeTable td {
  border: unset !important;
  border-radius: 0.6rem;
  border-color: unset !important;
  font-weight: unset !important;
  color: unset !important;
  background-color: unset !important;
}
.OrgMigration__TreeTable th {
  border: unset !important;
  color: unset !important;
  background-color: unset !important;
}

/*
  Reference for using Adobe-Clean fonts (including fallbacks) for CCJK locales
    - https://git.corp.adobe.com/world-readiness/globalization-shared/blob/master/guidelines/fonts/03_using_adobe_clean_fonts_for_web_app.md
*/

/* 
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for most locales
  Also provides general text styling for Tree Table
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
*/
.OrgMigration__TreeTableColumn {
  font-family: adobe-clean, 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  word-break: break-all;
}

/* 
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Chinese Simpified locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-simplified-c is not expected to render without typekit, instead SimSun is the expected fallback
*/
.OrgMigration__TreeTableColumn:lang(zh-Hans) {
  font-family: 'adobe-clean-han-simplified-c', 'SimSun', 'Heiti SC Light', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Chinese Traditional locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-traditional is not expected to render without typekit, instead MingLiu is the expected fallback
*/
.OrgMigration__TreeTableColumn:lang(zh) {
  font-family: 'adobe-clean-han-traditional', 'MingLiu', 'Heiti TC Light', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Korean locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-korean is not expected to render without typekit, instead Malgun Gothic is the expected fallback
*/
.OrgMigration__TreeTableColumn:lang(ko) {
  font-family: 'adobe-clean-han-korean', 'Malgun Gothic', 'Apple Gothic', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Japanese locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-japanese is not expected to render without typekit, instead Yu Gothic is the expected fallback
*/
.OrgMigration__TreeTableColumn:lang(ja) {
  font-family: 'adobe-clean-han-japanese', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Osaka', YuGothic,
    'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
}

.ExpirationIcon__alertIcon {
  margin-top: -0.1rem;
  margin-left: 0.2rem;
}

.spectrum--light .spectrum-ActionButton .ExpirationIcon__alertIconWarning {
  color: #cb6f10;
}

.spectrum--light .spectrum-ActionButton .ExpirationIcon__alertIconError {
  color: #c9252d;
}

.spectrum--light .spectrum-ActionButton:hover .ExpirationIcon__alertIconWarningHover {
  color: #bd640d;
}

.spectrum--light .spectrum-ActionButton:hover .ExpirationIcon__alertIconErrorHover {
  color: #bb121a;
}

/* when icon selected by keyboard */
.spectrum--light .spectrum-ActionButton.focus-ring .ExpirationIcon__alertIconWarning {
  color: #bd640d;
}

/* when icon selected by keyboard */
.spectrum--light .spectrum-ActionButton.focus-ring .ExpirationIcon__alertIconError {
  color: #bb121a;
}

.ExpirationIcon__alertIconTextField {
  margin-left: 0.9rem;
}

.ExpirationIcon__alertTooltip {
  max-width: 30%;
}

.ExpirationIcon__tooltipButton {
  margin-top: 1rem;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  float: right;
}

.modalBody {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: 'relative';
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.text {
  margin-bottom: 10px;
}

.icon {
  font-size: 50px;
}

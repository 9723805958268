.Insights_body {
  margin-top: 1rem;
  align-items: center;
}

.Insights__headerDiv {
  grid-row: 1 / 2;
}

.Insights_option_list {
  flex-grow: 0.1;
  margin-top: 10px;
}

.Insights__btn {
  float: right;
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
}

.Insights_container {
  display: flex;
  justify-content: space-between;
}

.grid_reports_list {
  flex-grow: 1;
  margin-top: 10px;
}

.ReportsInfoTable__Table {
  width: 100%;
}

.ReportsInfoTable__Name {
  width: 18%;
}

.ReportsInfoTable__SmallInfo {
  width: 13%;
}

.ReportsInfoTable__Action {
  width: 43%;
}

.ReportsInfoTable__CapitalizeWord::first-letter {
  text-transform: capitalize;
}

.ReportsInfoTable__Row {
  height: 3rem;
}

.Storage_Reports_default {
  align-content: center;
  text-align: center;
  padding-top: 100px;
}

.ExportReport__ALink {
  color: #007ad9;
  cursor: pointer;
}

.ReportInfo__scrollableContainer {
  display: inline-block;
  background-color: #fcfcfc;
  width: 100%;
  margin-top: 1rem;
}

.ReportInfo__totalCountLabel {
  font-weight: bold;
}

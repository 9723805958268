.OrgMigrationSelect__pathToTake {
  margin-top: 13rem;
}

.OrgMigration__error {
  color: red;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.OrgMigration__btncontainer {
  max-width: 1000px;
  margin-left: 100px;
}

.OrgMigration__btn {
  margin-top: 1rem;
}

.Insights_option_list {
  flex-grow: 0.1;
  margin-top: 10px;
}

.grid_reports_list {
  flex-grow: 1;
  margin-top: 10px;
  margin-bottom: 35px; /* leave room for footer */
  min-width: 600px; /* make sure that the element and children dont move when browser is resized */
}

.ProductTable__infoContainer {
  margin-left: auto; /* This will make the element "float" right in a flex container */
}

.ProductTable__quotaInfo {
  display: inline-block;
  font-size: var(--spectrum-global-dimension-static-font-size-75);
  font-weight: var(--spectrum-global-font-weight-regular);
  margin-right: 1rem;
  width: 8rem;
  text-transform: capitalize;
  vertical-align: center;
}

.ProductTable__productIcon {
  width: 36px;
  height: 36px;
}

.ProductTable__productName {
  text-transform: none; /* prevent capitalization caused by rsv2 accordion */
  font-size: var(--spectrum-global-dimension-static-font-size-100);
  font-weight: var(--spectrum-global-font-weight-bold);
}

.ProductTable__contractName {
  clear: both;
  text-transform: none; /* prevent capitalization caused by rsv2 accordion */
  font-size: var(--spectrum-global-dimension-static-font-size-75);
  font-weight: var(--spectrum-global-font-weight-regular);
  max-width: var(--spectrum-global-dimension-static-size-6000);
}

.ProductTable__contractTag {
  margin-left: 1rem;
  margin-top: 0px;
  text-transform: none; /* prevent capitalization caused by rsv2 accordion */
}

.ProductTable__accordian {
  margin-bottom: 0.5rem;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.ProductTable__accordian .spectrum-Accordion-itemIndicator {
  margin-top: 0.65rem;
}

.ProductTable__quotaContainer {
  width: 100%;
}

.ProductTable__contractType {
  margin-left: 1rem;
  margin-top: 0px;
}

.spectrum--light .ProductTable__expiringLabel,
.spectrum--light .ProductTable__expiredLabel {
  font-weight: 100;
  color: white;
  border-color: unset;
  margin-right: 2rem;
  text-transform: none; /* prevent capitalization caused by rsv2 accordion */
}

.spectrum--light .ProductTable__expiringLabel,
.spectrum--light:hover .ProductTable__expiringLabel:hover {
  background-color: #e68823;
  color: white;
}

.spectrum--light .ProductTable__expiredLabel,
.spectrum--light:hover .ProductTable__expiredLabel:hover {
  background-color: #c9252d;
  color: white;
}

.OrgMigrationStep__list {
  margin: 1rem;
}

.OrgMigration__footer {
  position: relative;
  padding: 1rem;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.OrgMigrationGrid__layout {
  margin-bottom: 2rem;
}

.OrgMigration__loadIcon {
  position: absolute;
  margin-left: 1rem;
}

.StepControl__actionBtns {
  float: right;
  margin-top: 1.5rem;
  margin-right: 1.5rem;
}

.StepControl__stepList {
  margin-bottom: 3rem;
}

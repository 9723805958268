.CompartmentPolicy_subheading {
  margin-top: 1rem;
  font-weight: bold;
}

.CompartmentPolicy label {
  display: block;
}

.CompartmentPolicy_section {
  margin-bottom: 50px;
  margin-top: 30px;
}

.spectrum-Table-cell {
  padding: 5px 16px;
  vertical-align: middle;
}

.spectrum-Icon.spectrum-Icon--sizeS.CompartmentPolicy__lock--open {
  color: #d5d7d5;
}

/* `.policies-ToggleSwitch .spectrum-ToggleSwitch-label` would ensure label text associated with <Switch/> doesn't break up onto separate lines upon resizing. Ref: https://jira.corp.adobe.com/browse/BANY-518 */
.policies-ToggleSwitch .spectrum-ToggleSwitch-label {
  white-space: nowrap;
}

.spectrum-Table-cell.CompartmentPolicy--not-supported,
.spectrum--light
  .spectrum-ToggleSwitch.CompartmentPolicy--not-supported
  .spectrum-ToggleSwitch-input:disabled:checked
  ~ .spectrum-ToggleSwitch-label,
.spectrum--light
  .spectrum-ToggleSwitch.CompartmentPolicy--not-supported
  .spectrum-ToggleSwitch-input:disabled
  ~ .spectrum-ToggleSwitch-label {
  color: #b3b3b3;
}

.ExportDialog__container {
  height: 85vh;
  width: 33%;
}

.ExportDialog__formatContainer {
  margin-top: 1rem;
}

.ExportDialog__title {
  font-weight: bold;
}

.ExportDialog__Content {
  margin-left: 2rem;
}

.ExportDialog__CheckBox {
  justify-content: left;
}

.ExportDialog__errorMessage {
  color: red;
  text-align: center;
  margin-bottom: 2rem;
}

.ExportDialog_successMessage {
  color: #1473e6;
  margin-bottom: 2rem;
  overflow-x: auto;
}

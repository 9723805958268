.ProfileSection__usergroup {
  padding-bottom: 0.2rem;
  padding-left: 2px; /*Needed for keyboard focus outline*/
}

.ProfileSection__load {
  display: inline-block;
  /*align-content: center;*/
  margin-left: 1rem;
}

/* Ensure disabled <Switch/> with value set to `checked=true` would have specific color and not the default gray */

.spectrum--light
  .spectrum-ToggleSwitch
  .spectrum-ToggleSwitch-input:disabled:checked
  + .spectrum-ToggleSwitch-switch::before {
  background-color: #98c1f3;
}

.spectrum--light
  .spectrum-ToggleSwitch
  .spectrum-ToggleSwitch-input:disabled:checked
  + .spectrum-ToggleSwitch-switch::after {
  border-color: #98c1f3;
}

/* Ensure disabled <Switch/> would have its label text color set to specific color */

.spectrum--light .spectrum-ToggleSwitch .spectrum-ToggleSwitch-input:disabled:checked ~ .spectrum-ToggleSwitch-label {
  color: #4b4b4b;
}

.spectrum--light .spectrum-ToggleSwitch .spectrum-ToggleSwitch-input:disabled ~ .spectrum-ToggleSwitch-label {
  color: #4b4b4b;
}

/* Ensure no borders for elements of <Combobox/> component. */

/* Each combobox uses `spectrum-InputGroup`, `.spectrum-Textfield`, `spectrum-FieldButton` with specific events as defined below.*/

.spectrum--light .spectrum-InputGroup--quiet .spectrum-Textfield--quiet {
  border: none;
}

.spectrum--light .spectrum-Textfield--quiet:focus {
  box-shadow: none;
}

.spectrum--light .spectrum-InputGroup--quiet .spectrum-FieldButton--quiet {
  border: none;
}

.spectrum--light .spectrum-InputGroup--quiet .spectrum-InputGroup-field:focus ~ .spectrum-FieldButton {
  box-shadow: none;
  border: none;
}

.spectrum--light
  .spectrum-InputGroup.is-focused:not(.is_invalid)
  .spectrum-InputGroup-field:not(:disabled):not(.is-invalid) {
  border: none;
}

.spectrum--light
  .spectrum-InputGroup.is-focused:not(.is_invalid)
  .spectrum-InputGroup-field:not(:disabled):not(.is-invalid)
  ~ .spectrum-FieldButton {
  border: none;
}

/* Ensure no borders for elements of <Table/> component */

.spectrum--light tbody.PolicyList__table-body .spectrum-Table-cell {
  border-top: none;
  border-bottom: 1px solid rgb(225, 225, 225);
  padding-left: 0px;
}

/* Following min-width was determined using width in spectrum's default css, when the <Switch/> is visible. */

/* This would avoid <Table/> from re-sizing when toggling visibility of <Switch>. */

.table__td--switch-absence {
  min-width: 125px;
}

/* Following min-width was determined using width in spectrum's default css, when the <Combobox/> is visible. */

/* This would avoid <Table/> from re-sizing when toggling visibility of <Combobox>. */

.table__td--lock-action-absence {
  min-width: 250px;
}

.LockActionIcon {
  margin-right: 2px;
  vertical-align: middle;
}

.spectrum--light .PolicyFieldLabel {
  font-size: 14px;
  color: #4b4b4b;
}

/* `.policies-ToggleSwitch .spectrum-ToggleSwitch-label` would ensure label text associated with <Switch/> doesn't break up onto separate lines upon resizing. Ref: https://jira.corp.adobe.com/browse/BANY-518 */
.policies-ToggleSwitch .spectrum-ToggleSwitch-label {
  white-space: nowrap;
}

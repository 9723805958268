.orgReparentProductsMessages__listItem {
  padding-left: 2rem;
}

.orgReparentProductsMessages__long {
  width: max-content;
}

.orgReparentProductsMessages__label {
  color: rgb(44, 44, 44);
}

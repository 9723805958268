.JobExecution__wrapper {
  margin: 1em;
}

.JobExecution__wrapper .spectrum-Accordion-itemContent {
  padding: 0 !important;
}

.JobExecution__ChangesTable {
  width: 100%;
}

.JobExecution__recentJobs__Accordion {
  margin-top: 2rem;
  border: 0;
}
.JobExecution__recentJobs {
  text-align: left; /* necessary for Edge, which centers otherwise */
  text-transform: lowercase;
  font-size: larger;
  width: 100%;
}
.JobExecution__recentJobsHeading {
  margin-left: 2rem;
  width: 100%;
}

.JobExecution__recentJobsSteps {
  width: 100%;
}

.JobExecution__recentJobsSteps__commandInfo {
  width: 60%;
}

.JobExecution__recentJobsSteps__commandInfoHeading {
  margin-left: 40px;
}

.JobExecution__recentJobsSteps__commandStatus {
  width: 40%;
}

.JobExecution__jobProgressBar {
  margin-top: 6px;
}

.JobExecution__recentJobsSteps__commandNotes {
  margin-left: 30px;
}

.JobExecution__recentJobsSteps__commandDescrpnsList {
  list-style-position: outside;
  margin: 0;
}

.JobExecution__recentJobsSteps__commandDescrpnsListItem {
  margin-left: -25px;
}

.JobExecution__recentJobsSteps__commandDescrpnsNumberList {
  list-style-type: none;
  margin: 0;
}

.JobExecution__recentJobsSteps__commandDescrpnsNumberItemLabel {
  display: inline-block;
  width: 30px;
  margin-left: -30px;
}
.JobExecution__recentJobsSteps__commandDescrpn_spacing {
  white-space: pre;
}

.JobExecution__recentJobsSteps__commandNotes__icon {
  margin-left: -20px;
}

.JobExecution__recentJobsSteps__nextPageStatusHeader {
  font-weight: bold;
  margin-top: 0.5rem;
  margin-left: 1rem;
}

/* widths */
.JobExecution__recentJobs > tbody > tr > td:nth-child(1) {
  width: 3rem;
}
.JobExecution__recentJobs > tbody > tr > td:nth-child(2) {
  width: 10rem;
}
.JobExecution__recentJobs > tbody > tr > td:nth-child(3) {
  width: 8rem;
}
.JobExecution__recentJobs > tbody > tr > td:nth-child(4) {
  width: 15rem;
}
.JobExecution__recentJobs > tbody > tr > td:nth-child(5) {
  width: 18rem;
}
.JobExecution__recentJobsHeading > thead > tr > th:nth-child(1) {
  width: 3rem;
}
.JobExecution__recentJobsHeading > thead > tr > th:nth-child(2) {
  width: 10rem;
}
.JobExecution__recentJobsHeading > thead > tr > th:nth-child(3) {
  width: 8rem;
}
.JobExecution__recentJobsHeading > thead > tr > th:nth-child(4) {
  width: 15rem;
}
.JobExecution__recentJobsHeading > thead > tr > th:nth-child(5) {
  width: 19rem;
}

.JobExecution__recentJobs__checkMark {
  color: green;
}

.JobExecution__recentJobs__alertIcon {
  color: red;
}

.JobExecution__recentJobs__Accordion_heading {
  font-weight: bolder;
  font-size: larger;
}

.JobExecution__jobNumber {
  float: right;
}

.JobExecution__jobFailed__alertIcon {
  color: red;
  margin-left: 0.5rem;
}

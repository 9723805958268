/*!*override primereact css*!*/

.OrgTree tr {
  font-weight: unset !important;
  color: unset !important;
  background: inherit !important;
}

.OrgTree table {
  /*
  spacing between rows is necessary to match the spectrum side navigation
   */
  border-collapse: separate !important;
  border-spacing: 4px !important;
}

.OrgTree .p-treetable .p-treetable-tbody > tr:focus + tr > td {
  /*
   + sign means the element is placed immediately after the other element https://www.w3schools.com/cssref/sel_element_pluss.asp
   > sign means the element is immediate child of the other element https://www.w3schools.com/cssref/sel_element_gt.asp
   tr>td means the table cell within the table row
   tr:focus+tr>td means table cell inside the table row next to the table row in focus
   prime react library seems to be applying box-shadow to the top of table cell of the next row instead of the row in focus
   this seems to be a bug on their side. So we need to unset their css to get the right behavior.
   This change would fix: https://jira.corp.adobe.com/browse/BANY-588
  */
  box-shadow: unset !important; /* do not show the prime react box shadow */
  outline: unset !important;
}

.OrgTree .p-treetable .p-treetable-tbody > tr:focus > td {
  /* >tr:focus>td applies the following css on the table cell which is inside the table row in focus */
  box-shadow: unset !important; /* do not show the prime react box shadow */
  outline: unset !important; /* unset the prime react css and let browser show the default outline */
}

.OrgTree td:hover {
  background-color: rgba(44, 44, 44, 0.06) !important;
}

.OrgTree tr.p-highlight {
  background-color: rgba(44, 44, 44, 0.06) !important;
}

.OrgTree tr.p-highlight .p-treetable-toggler {
  color: unset !important;
}

.OrgTree td {
  border: unset !important;
  border-radius: 4px !important;
  border-color: unset !important;
  font-weight: unset !important;
  color: unset !important;
  background-color: unset !important;
}

.OrgTree th {
  border: unset !important;
  color: unset !important;
  background-color: unset !important;
}

.OrgTree_search {
  margin-left: 1rem;
  display: inline-block;
}

.OrgTree_products {
  width: 6rem;
  text-align: center;
}

/*
  Reference for using Adobe-Clean fonts (including fallbacks) for CCJK locales
    - https://git.corp.adobe.com/world-readiness/globalization-shared/blob/master/guidelines/fonts/03_using_adobe_clean_fonts_for_web_app.md
*/

/* 
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for most locales 
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
*/
.OrgTree_column {
  font-family: adobe-clean, 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

/* 
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Chinese Simpified locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-simplified-c is not expected to render without typekit, instead SimSun is the expected fallback
*/
.OrgTree_column:lang(zh-Hans) {
  font-family: 'adobe-clean-han-simplified-c', 'SimSun', 'Heiti SC Light', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Chinese Traditional locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-traditional is not expected to render without typekit, instead MingLiu is the expected fallback
*/
.OrgTree_column:lang(zh) {
  font-family: 'adobe-clean-han-traditional', 'MingLiu', 'Heiti TC Light', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Korean locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-korean is not expected to render without typekit, instead Malgun Gothic is the expected fallback
*/
.OrgTree_column:lang(ko) {
  font-family: 'adobe-clean-han-korean', 'Malgun Gothic', 'Apple Gothic', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Japanese locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-japanese is not expected to render without typekit, instead Yu Gothic is the expected fallback
*/
.OrgTree_column:lang(ja) {
  font-family: 'adobe-clean-han-japanese', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Osaka', YuGothic,
    'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
}

.OrgTree {
  padding-top: 0.5rem;
  border-right: 0.05rem solid #e1e1e1;
  margin-top: 1rem; /* align with organization name and other icon in second section */
}

.OrgTree__TreeTable {
  padding-left: 0.5rem;
  border-radius: 0.5rem;
  padding-right: 1rem;
}

.OrgTree_editedName {
  font-weight: bolder;
}

.OrgTree_EditIcon {
  margin-left: 0.8rem;
}

.OrgTree_dropZone {
  display: inline-block;
  padding: unset;
  border-style: unset;
  border: unset;
}

.OrgTree_dragged {
  border: 0.1rem solid #979797;
  background-color: #c8c8c8;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.24rem;
  padding-left: 0.5rem;
}

.OrgTree_orgName {
  word-break: break-all;
}

.OrgTree_reparentIcon {
  margin-left: 0.5rem;
}

.OrgTree__loadingIcon {
  margin-left: 0.5rem;
  vertical-align: middle;
}

.OrgTree_deletedOrg {
  color: red;
  text-decoration: line-through;
}

.OrgTree_newOrg {
  font-weight: lighter;
  font-size: smaller;
  color: grey;
  margin-left: 1rem;
  border: 0.05rem solid;
  border-radius: 0.3rem;
  padding: 0 0.5rem 0.1rem 0.5rem;
  display: inline-block; /* fixes https://jira.corp.adobe.com/browse/BANY-559 */
}

.OrgTree_filteredOrg {
  font-weight: bold;
  background-color: rgba(255, 255, 0, 0.15);
}
.OrgTree__EditStructure {
  margin-right: 1rem;
}

.OrgTree_header {
  display: flex;
  justify-content: space-between;
}

.OrgTree_reparentDialog {
  max-width: 50%;
}

.App__header {
  display: inline-block;
  font-size: 36px; /* make it look similar for all languages. zh_TW, ja_JP, ko_KR have this font-size defined by spectrum */
  line-height: 1.5; /* make it look similar for all languages. zh_TW, ja_JP, ko_KR  have this line-height defined by spectrum */
}

.App__content {
  margin-left: 2rem;
  margin-right: 2rem;
  padding-bottom: 3rem;
}

.App__headerButtons {
  margin-top: 1.5rem;
  float: right;
}

.App__alert {
  margin-left: 1rem;
}

.ApplyTemplate_treeTable {
  max-height: fit-content;
  padding-left: 0rem;
  border-radius: 0rem;
  overflow: auto;
  padding-top: 1rem;
}

.ApplyTemplate_treeTable .p-treetable .p-treetable-tbody > tr > td {
  overflow-wrap: break-word;
  /* Same as overflow-wrap but Firefox (< v49) supports word-wrap only*/
  word-wrap: break-word;
}

.ApplyTemplate_treeTable_selectColumn {
  /* this value was determined based on dimensions of <Checkbox/> icon component */
  width: 39px;
}

.ApplyTemplate_treeTable_selectColumn .spectrum-Checkbox {
  /* this value was customized to avoid unwanted scrollbar */
  margin-right: 0px;
}

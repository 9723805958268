.ProdProfileDialogContent__container {
  height: 90vh;
  width: 36rem;
}

.ProdProfileDialogContent__ErrorMessage {
  display: block;
  padding-top: 0.5rem;
  color: #ff1d2e !important; /* Override spectrum-FieldLabel color attribute here */
}

.ProdProfileDialogContent__ErrorMessageMargins {
  margin-bottom: 2rem;
}

.ProdProfileDialogContent__noContent {
  text-align: center;
}

.ProdProfileDialogContent__QuantityField {
  padding-top: 1rem;
}
.ProdProfileDialogContent__NameInput {
  width: 100%;
}

.ProdProfileDialogContent__NameInputForSingleApp {
  width: 70%;
  display: inline-block;
}
.ProdProfileDialogContent__NamePrefix {
  width: 29%;
  margin-right: 1%;
}
.ProdProfileDialogContent__NamePrefix:disabled::placeholder {
  /* override spectrum font style which is usually italic style */
  font-style: normal;
}
.ProdProfileDialogContent__NameTooltip {
  /* override spectrum tooltip css. Otherwise tooltip is too long vertically and too short horizontally. */
  display: unset;
  width: 13rem;
}

.ProdProfileDialogContent__SelectProduct {
  width: 100%;
}

.EditCompartment {
  padding: 1rem;
}

.EditCompartment_heading {
  display: inline-block;
  font-weight: 300;
  font-size: 1.6rem;
  white-space: nowrap;
  max-width: 40rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.EditCompartment_headingTooltip {
  /* override spectrum tooltip css. Otherwise tooltip is too long vertically and too short horizontally. */
  max-width: 35rem;
  display: unset;
}

.EditCompartment_actionButtons {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  display: inline-block;
  float: right;
}

.EditCompartment_orgDeleteMessage {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: red;
}

.EditCompartment_warning {
  color: red;
  margin-top: 2rem;
  font-weight: bold;
}

.EditCompartment_showInlineBlock {
  display: inline-block;
}

.EditCompartment_showBlock {
  display: inline-block;
  width: 100%;
}

.EditCompartment_hidden {
  display: none;
}

.EditCompartment_orgInfoDiv {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #979797;
  font-size: smaller;
}

.EditCompartment_orgInfo {
  display: inline-block;
  margin-right: 1rem;
  padding-right: 0.5rem;
}

.EditCompartment_orgInfoBorder {
  border-right: #e3e3e3 0.09rem solid;
}

/* Hack!!! Hack to avoid clipping of Textfield's valid & invalid icons for rsv3 in Add/Edit org dialogs */
[dir='ltr'] .spectrum-Textfield--valid_73bc77 .spectrum-Textfield-validationIcon_73bc77,
[dir='ltr'] .spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  padding-right: 0;
}

.FullErrorPage {
  height: 100%;
}

.FullErrorPage_errorMessageContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 30%;
  text-align: center;
}

.FullErrorPage_image {
  width: 10%;
  height: 10%;
}

.FullErrorPage_errorHeading {
  font-size: 1.5rem;
  font-weight: lighter;
}

.ProductAllocation__header__buttons__importExport__dropdown {
  float: right;
}

.ProductAllocation__header__buttons__importExport__button {
  min-width: unset;
  height: unset;
  padding: 0.1rem 0.3rem 0 0.3rem;
  display: inline-block;
  margin-left: 1rem;
}

.ProductAllocation__header__writeLoad {
  margin-left: 7rem;
}

.ProductAllocation__header__writeLoad__message {
  margin-left: 0.5rem;
}

.ProductAllocation__dropdown {
  float: left;
  padding-top: 0.7rem;
  padding-left: 0.5rem;
  padding-right: 2rem;
  width: 27rem;
}

.ProductAllocation__dropdown__menu {
  width: 27rem;
}

.ProductAllocation__dropdown__icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.ProductAllocation__alert {
  margin-left: 1.5rem;
  margin-right: 6rem;
}

.ProductAllocation__table__addOption {
  float: right;
  cursor: pointer;
  border: unset;
  margin-right: 1.5rem;
}

.ProductAllocation__table__addOption__text:hover {
  /* The + text looks like ± unless we disable the underline */
  text-decoration: none;
}

.ProductAllocation__header__deleteToggleButton {
  float: right;
}

.ProductAllocation__trashcan--hidden {
  visibility: hidden;
}

.ProductAllocation__table__redisTooltip {
  color: red;
  cursor: pointer;
  float: right;
  margin-right: 2.3rem;
}

.ProductAllocation__table__readonlyTooltip {
  color: red;
  cursor: pointer;
  float: right;
  margin-right: 2.3rem;
}

.ProductAllocation__body {
  padding-top: 1rem;
  display: inline-flex;
  flex-direction: column;
}

.ProductAllocation__body .p-treetable .p-treetable-tbody > tr:focus + tr > td {
  /*
   + sign means the element is placed immediately after the other element https://www.w3schools.com/cssref/sel_element_pluss.asp
   > sign means the element is immediate child of the other element https://www.w3schools.com/cssref/sel_element_gt.asp
   tr>td means the table cell within the table row
   tr:focus+tr>td means table cell inside the table row next to the table row in focus
   prime react library seems to be applying box-shadow to the top of table cell of the next row instead of the row in focus
   this seems to be a bug on their side. So we need to unset their css to get the right behavior.
   This change would fix: https://jira.corp.adobe.com/browse/BANY-588
  */
  box-shadow: unset !important; /* do not show the prime react box shadow */
  outline: unset !important; /* unset the prime react css and let browser show the default outline */
}

.ProductAllocation__body .p-treetable .p-treetable-tbody > tr:focus > td {
  /* >tr:focus>td applies the following css on the table cell which is inside the table row in focus */
  box-shadow: unset !important; /* do not show the prime react box shadow */
  outline: unset !important; /* unset the prime react css and let browser show the default outline */
}

/* ProductAllocation Table */

.ProductAllocation__table__container {
  flex: 1;
  overflow-y: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ProductAllocation__table {
  background: #ffffff;
}

.ProductAllocation__table__inputField {
  text-align: right;
  width: 80%; /* leave room for trash icon */
}

.ProductAllocation__table__numField {
  text-align: right;
  width: 70%; /* re-size textfield so that right alignment lines up with editable input fields */
  padding-top: 0.35rem;
  padding-left: 0.5rem;
}

/* override primereact style */

.ProductAllocation__table tr {
  font-weight: unset !important;
  color: unset !important;
}

.ProductAllocation__table tr.p-highlight {
  background-color: #c9c9c9 !important;
}

.ProductAllocation__table tr.p-highlight .p-treetable-toggler {
  color: unset !important;
}

.ProductAllocation__table td {
  border: 1px solid;
  border-color: rgb(255, 255, 255);
  font-weight: unset !important;
  color: unset !important;
  background-color: unset !important;
}

.ProductAllocation__table th {
  border: 1px solid;
  border-color: rgb(255, 255, 255);
  color: unset !important;
  background-color: unset !important;
}

.ProductAllocation__table__header__unitColor {
  color: rgb(110, 110, 110);
}

.ProductAllocation__table__header__column {
  word-wrap: anywhere;
}

.ProductAllocation__table__column__compartments {
  width: 25rem;
}

/*
  Reference for using Adobe-Clean fonts (including fallbacks) for CCJK locales
    - https://git.corp.adobe.com/world-readiness/globalization-shared/blob/master/guidelines/fonts/03_using_adobe_clean_fonts_for_web_app.md
*/

/* 
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for most locales 
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
*/
.ProductAllocation__table__column {
  font-family: adobe-clean, 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

/* 
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Chinese Simpified locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-simplified-c is not expected to render without typekit, instead SimSun is the expected fallback
*/
.ProductAllocation__table__column:lang(zh-Hans) {
  font-family: 'adobe-clean-han-simplified-c', 'SimSun', 'Heiti SC Light', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Chinese Traditional locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-traditional is not expected to render without typekit, instead MingLiu is the expected fallback
*/
.ProductAllocation__table__column:lang(zh) {
  font-family: 'adobe-clean-han-traditional', 'MingLiu', 'Heiti TC Light', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Korean locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-korean is not expected to render without typekit, instead Malgun Gothic is the expected fallback
*/
.ProductAllocation__table__column:lang(ko) {
  font-family: 'adobe-clean-han-korean', 'Malgun Gothic', 'Apple Gothic', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Japanese locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-japanese is not expected to render without typekit, instead Yu Gothic is the expected fallback
*/
.ProductAllocation__table__column:lang(ja) {
  font-family: 'adobe-clean-han-japanese', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Osaka', YuGothic,
    'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
}

.ProductAllocation__table__column__grant {
  width: 9rem;
  white-space: nowrap; /* keep trash icon on same line */
}

.ProductAllocation__table__column__centered {
  text-align: center;
}

.ProductAllocation__table__column__right {
  text-align: right;
}

.ProductAllocation__table__column__over {
  color: darkorange;
}

.ProductAllocation__table__column__indicator {
  color: #5f9aff;
  cursor: pointer;
  padding-left: 0.5rem;
}

.ProductAllocation__table__column__tooltipWidth {
  width: 25rem;
}

.ProductAllocation__table__column__purchaseIcon {
  vertical-align: middle;
}

.ProductAllocation__table__column__editIcon {
  margin-left: 0.8rem;
  vertical-align: middle;
}

.ProductAllocation__table__column__wrap {
  word-break: break-all;
}

.ProductAllocation__table__column__space {
  margin-left: 0.3rem;
}

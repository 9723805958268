.AlertBanner__DeepLinkParser {
  width: calc(100vw - 4rem);
  margin-left: 2rem;
  margin-right: 2rem;
}

.AdobeAgentWithNoOrgMessage {
  position: fixed;
  left: 20%;
  width: 60%;
  height: 100%;
  top: 30%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: lighter;
}

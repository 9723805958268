.Footer_area {
  background-color: #f5f5f5;
  display: inline;
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.Footer__link {
  padding-left: 3em;
  color: rgb(44, 44, 44) !important; /* override Spectrum blue link default */
}
.Footer__link:link {
  text-decoration: none;
}
.Footer__link:hover {
  text-decoration: underline;
}

.Footer__label {
  padding-left: 3em;
}

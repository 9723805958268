.AlertBanner,
.AlertBanner__noHeader {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  min-width: unset;
  min-height: unset;
}

.AlertBanner .spectrum-Alert-icon,
.AlertBanner__noHeader .spectrum-Alert-icon {
  top: 7px;
  left: 5px;
}

.spectrum-Alert-content {
  /* this value was defined to ensure ".AlertBanner__buttons" has a *visible* left border. (BANY-1071) */
  display: flex;
}

.AlertBanner__content {
  padding-right: 10px;
  flex: 1 1 auto;
  align-self: center;
}

.AlertBanner__buttons {
  display: flex;
  flex: 0 0 auto;
  align-items: flex-start;
  border-left-color: rgb(110, 110, 110, 0.2);
}

.AlertBanner__content + .AlertBanner__buttons {
  border-left-style: solid;
  border-left-width: 1px;
}

.AlertBanner__close {
  position: absolute;
  top: 7px;
  right: 5px;
  width: 20px;
  height: 20px;
  min-width: 0;
}

.AlertBanner .spectrum-Alert-header {
  padding-right: unset;
}

.AlertBanner__noHeader .spectrum-Alert-header {
  display: none;
}

.AlertBanner .spectrum-Alert-content,
.AlertBanner__noHeader .spectrum-Alert-content {
  margin: 0;
}

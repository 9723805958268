.SearchOrg__selectList {
  width: 100%;
}

/* Update border for each li element in the select list */
.SearchOrg__selectList li {
  border-bottom: solid 0.1px lightgrey;
}

.SearchOrg__selectListContainer {
  height: 25rem;
}

.SearchOrg__selectedOrgList {
  display: inline-block;
  background-color: #fcfcfc;
  box-sizing: border-box;
  height: 25rem;
  overflow-y: scroll;
  width: 150%;
  margin-top: 4rem;
}

.SearchOrg__errorMessage {
  color: red;
  text-align: left;
  margin-left: 2rem;
}

.SearchOrg__loadStatus {
  color: #007ad9;
  font-weight: bold;
  margin-top: 0.5rem;
}

.SearchOrg__scrollableContainer {
  display: inline-block;
  background-color: #fcfcfc;
  height: 25rem;
  width: 100%;
}

.SearchOrg__container {
  width: 100%;
}

.SearchOrg__combobox {
  max-width: 150px;
  margin-left: 2rem;
  margin-right: 1rem;
}

.SearchOrg__searchBox {
  display: inline-block;
  width: 400px;
  margin-right: 1.5rem;
}

.SearchOrg__searchBox input {
  width: 100%;
}

.SearchOrg__selectListElem {
  height: 1.7rem;
  width: 100%;
}

.SearchOrg__selectListHeaderContainer {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.SearchOrg__selectListHeaderColumn1 {
  width: 60%;
  text-align: left;
  margin-left: 2rem;
  font-size: smaller;
  color: grey;
}

.SearchOrg__selectListHeaderColumn2 {
  width: 40%;
  float: right;
  text-align: left;
  margin-left: 2rem;
  font-size: smaller;
  color: grey;
}

.SearchOrg__selectListColumn1 {
  width: 60%;
  text-align: left;
}

.SearchOrg__selectListColumn2 {
  width: 40%;
  float: right;
  text-align: left;
}

.SearchOrg__wait {
  margin-left: 1rem;
}

.SearchOrg__footerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.SearchOrg__selectAll {
  float: right;
}

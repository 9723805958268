.SearchOrganizationContent__descriptionContainer {
  margin-bottom: 2rem;
}

.SearchOrganizationContent__descriptionHeader {
  font-weight: bold;
  font-size: medium;
}

.SearchOrganizationContent__description {
  font-size: small;
  margin-top: 1rem;
}

.EditCompartment__deleteConfirmDialog__warningHeader {
  color: red;
  margin-top: 1rem;
  font-weight: bold;
}

.EditCompartment__deleteConfirmDialog__warning {
  color: red;
  font-weight: bold;
}

.SelectOrganization__TreeTableContainer {
  margin-top: 2rem;
}

.SelectOrganization__CheckEligibilityBtn {
  border: none;
  color: #59abe3;
  background-color: inherit;
}

.SelectOrganization_treeTable_selectColumn {
  width: 39px;
}

.SelectOrganization_treeTable_checkEligibilityColumn {
  width: 12%;
}

.SelectOrganization_treeTable_eligibilityStatusColumn {
  width: 38%;
}

.SelectOrganization__treeTable__checkEligibilityOption {
  cursor: pointer;
  border: unset;
  margin-right: 1.5rem;
}

.SelectOrganization__CheckEligibilityContainer {
  display: flex;
  flex-direction: row;
}

.SelectOrganization__CheckEligibilityWait {
  margin-top: 0.5rem;
}

.SelectOrganization__treeTable__eligibilityStatusCont {
  display: flex;
  justify-content: left;
}

.SelectOrganization__treeTable__eligibilityFailureList {
  padding-left: 1rem;
}

.SelectOrganization__treeTable__eligibilityFailureListMessage {
  width: 100%;
}

.CreateTemplate__headerMessage {
  padding-bottom: 15px;
}

.CreateTemplate__fieldGroup {
  display: inline-flex;
}

.CreateTemplate__fieldContainer {
  padding-right: 50px;
}

.CreateTemplate__fieldLabel {
  margin-right: 2rem;
  font-weight: 600;
  font-size: 14px;
}

.CreateTemplate__policyWell {
  padding-left: 1px;
  padding-right: 1px;
  border: none;
  background-color: transparent;
}

.spectrum--light .spectrum-Well {
  background-color: transparent;
}

.CreateTemplate__text-area {
  min-height: 32px;
  min-width: 300px;
}

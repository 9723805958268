.AdminDialog {
  font-size: larger;
  width: 45%;
  min-width: 30rem;
  max-width: 40rem;
}

.AdminDialogSection {
  border-color: #c8c8c8 !important; /*overwrite border color of .spectrum--light .spectrum-Well*/
  margin-bottom: 1rem;
}

.AdminDialogUserDetailLabels {
  width: 20%;
  display: inline-block;
  color: grey;
}

.AdminDialogUserDetailValue {
  height: 1.5rem;
}

/* css for fields displayed in RO mode for View and Edit Admin Dialog */
.AdminDialogUserDetailsTextValue {
  word-wrap: break-word;
  max-width: 80%;
  float: left;
}

.AdminDialogUserDetailSubSection {
  margin-bottom: 0.5rem;
}

.AdminDialogUserDetailsContainer,
.AdminDialogSubHeading {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3rem;
}

.AdminDialogSubHeading {
  justify-content: space-between;
}

.AddAdminContent_heading {
  width: 40%;
}

.AdminTableRoles {
  max-width: 9.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.AdminRightSubsection {
  margin-top: 1rem;
}

.AdminInfoSubsection {
  margin-top: 0.5rem;
}

.AdminDialogErrorMessage {
  color: red;
  margin-left: 20%;
  margin-bottom: 0.5rem;
}

.AdminDialogInfoMessage {
  color: #7aaaff;
  font-size: smaller;
}

.AccountTypeInfoMessage {
  color: #7aaaff;
  padding-top: 1rem;
  font-size: smaller;
}

.OrgLevelAdminSwitch {
  float: right;
}

.AdminRightTagList {
  vertical-align: middle;
}

.AdminDialogUnderline {
  text-decoration: underline;
}

.AdminDialogNoContent {
  text-align: center;
  font-style: italic;
}

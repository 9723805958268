.ViewTemplate__headerMessage {
  padding-bottom: 15px;
}

.ViewTemplate__policyWell {
  padding-left: 1px;
  padding-right: 1px;
  border: none;
  background-color: transparent;
}

.BanyanShell__contentArea {
  height: calc(100vh - 35px);
}
/*div.spectrum-ShellContainer-content {*/
/*  bottom: 32px; !* Try to fix scroll problems where this isn't accounted for *!*/
/*}*/

span.solution-switcher-menu {
  display: none; /* don't display app switcher menu */
}

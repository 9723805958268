.AddProductDialog__UnitColumn {
  text-align: flex-start;
  position: relative;
  top: 0.3rem;
}
.AddProductDialog__container {
  width: 40rem;
}

.DelProductDialog__container {
  width: 40rem; /* without this, the dialog width is nearly full screen */
}

.AddProductDialog__title {
  word-break: break-all;
}

.AddProductDialog__ResourceName {
  color: #282c34;
  font-weight: 900;
}

.AddProductDialog__ResourceInput {
  text-align: flex-start;
  width: 17rem;
}

.AddProductDialog__ResourceText {
  width: 17rem;
}

/*
  Reference for using Adobe-Clean fonts (including fallbacks) for CCJK locales
    - https://git.corp.adobe.com/world-readiness/globalization-shared/blob/master/guidelines/fonts/03_using_adobe_clean_fonts_for_web_app.md
*/

/* 
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for most locales
  Also provides general text styling for Tree Table
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
*/
.AddProductDialog__TreeTableColumn {
  font-family: adobe-clean, 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  word-break: break-all;
}

/* 
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Chinese Simpified locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-simplified-c is not expected to render without typekit, instead SimSun is the expected fallback
*/
.AddProductDialog__TreeTableColumn:lang(zh-Hans) {
  font-family: 'adobe-clean-han-simplified-c', 'SimSun', 'Heiti SC Light', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Chinese Traditional locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-traditional is not expected to render without typekit, instead MingLiu is the expected fallback
*/
.AddProductDialog__TreeTableColumn:lang(zh) {
  font-family: 'adobe-clean-han-traditional', 'MingLiu', 'Heiti TC Light', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Korean locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-korean is not expected to render without typekit, instead Malgun Gothic is the expected fallback
*/
.AddProductDialog__TreeTableColumn:lang(ko) {
  font-family: 'adobe-clean-han-korean', 'Malgun Gothic', 'Apple Gothic', 'sans-serif';
}

/*
  Specify Adobe-Clean fonts and fallbacks for text in prime-react Tree Table for Japanese locale
  This makes the font of the prime-react Tree Table similar to React-Spectrum fonts
  adobe-clean-han-japanese is not expected to render without typekit, instead Yu Gothic is the expected fallback
*/
.AddProductDialog__TreeTableColumn:lang(ja) {
  font-family: 'adobe-clean-han-japanese', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Osaka', YuGothic,
    'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif';
}

/* overriding primereact styling for the treetable component
 removing lines, background coloring and borders.
 */

.DelProductDialog__Tree {
  font-weight: unset !important;
  font-family: unset !important;
  color: unset !important;
  background: inherit !important;
  border: unset !important;
}

.AddProductDialog__TreeTable tr {
  font-weight: unset !important;
  color: unset !important;
  background: inherit !important;
  border-radius: 1rem;
}
.AddProductDialog__TreeTable td {
  border: unset !important;
  border-radius: 0.6rem;
  border-color: unset !important;
  font-weight: unset !important;
  color: unset !important;
  background-color: unset !important;
}
.AddProductDialog__TreeTable th {
  border: unset !important;
  color: unset !important;
  background-color: unset !important;
}

.BanyanToastContainer {
  position: fixed;
}

.BanyanToast {
  margin: 0.5rem;
}

/* override spectrum CSS to make an action button have following properties */
.BanyanToast button {
  border-color: lightgrey !important;
}

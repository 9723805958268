.Compartments {
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: stretch;
  position: fixed;
  grid-auto-flow: row;
}

.Compartments__headerDiv {
  grid-row: 1 / 2;
}

.Compartments__reparentControl {
  padding-left: 2rem;
}

.Compartment__MenuItem {
  width: fit-content;
  text-align: center;
}

.Compartment__MoreButton {
  min-width: unset;
  height: unset;
  padding: 0.1rem 0.3rem 0 0.3rem;
  display: inline-block;
  margin-left: 1rem;
}

.Compartments_container {
  grid-row: 2 / 3;
  display: grid;
  grid-template: 100% / 100%;
}

.Compartments__fileUpload {
  display: none;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.OrgTree__container {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-auto-flow: column;
}

.Compartment__wait {
  margin: auto;
}

.EnabledServices__row {
  padding-bottom: 0.1rem;
}

.EnabledServices__emptyRow {
  margin: auto;
  display: table;
}

.EnabledServices__switch {
  float: right;
  vertical-align: middle;
}

.EnabledServices__label {
  padding-left: 0.3rem;
  vertical-align: middle;
}

.EnabledServices__image {
  height: 20px;
  margin: 5px;
  width: 20px;
  vertical-align: middle;
}
